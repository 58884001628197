import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  "| 스테이지파이브, 추석맞이 프로모션 진행…16일(목)부터 30일(목)까지 2주 한정 특별 혜택",
  "| 3만원 상당 백화점 상품권 제공에 11GB+ 요금제를 2년간 221GB+로 누릴 기회",
  "| “아이폰13시리즈 자급제 구매 계획 소비자 챙겨갈 만한 해…유일무이 9월 혜택, 꼭 챙기길”",
]

const content = () => (
  <div>
    <p>
      <br />
      <br />
      추석을 앞두고 스테이지파이브가 16일(목)부터 30일(목)까지 2주간 통신 데이터 추가 제공 및 백화점 상품권 증정의 특별 프로모션을
      진행한다.
      <br />
      <br />
      아이폰13 시리즈의 한국 출시가 코앞인 만큼 자급제 구매를 계획하는 소비자라면 통신 부담은 덜고, 상품권 혜택까지 챙겨갈 만한
      1석2조의 빅찬스이다.
      <br />
      <br />
      추석을 전후하여 진행되는 이번 프로모션은 총 두 가지이다. 먼저, 핀다이렉트샵 유심(알뜰폰) 요금제에 가입하는 이용자 전원에게
      가입 용량에 상관없이 3만원 상당의 신세계 백화점 상품권을 증정한다.
      <br />
      <br />
      또한 무제한 요금제를 합리적인 가격에 이용할 수 있는 마지막 혜택도 더해졌다. 데이터 추가 제공은 핀다이렉트샵 LTE 유심
      요금제(알뜰폰) △완전자유 11GB+ △완전자유 뭐이런걸다 11GB+(기프티쇼) 및 동급 용량의 제휴 요금제 전체에 24개월간 매월 150GB,
      매일 2GB를 추가 제공하여 총 221GB를 지원하는 9월의 마지막 특전이다. 진행 중이던 각종 추가할인도 중복 적용되어 월 2만원대에
      무제한 요금제를 즐길 수는 셈이다. 221GB 이후에도 3Mbps로 안정감 있게 데이터를 사용할 수 있는 것도 이 요금제의 장점이다.
      <br />
      <br />
      핀다이렉트샵 관계자는 “9월 중순 이후로는 알뜰폰 시장 전체가 숨 고르기에 들어가며 요금 할인이나 기타 혜택이 줄어들 것이라
      예상한다”면서 “대용량 데이터가 추가 제공되는 마지막 9월 혜택인 만큼 새로운 통신 요금제를 고민하는 분들은 꼭 챙겨 가시길
      바란다”고 전했다.
      <br />
      <br />
      한편, 핀다이렉트샵에서는 아이폰13시리즈 사전예약 알림 신청을 진행 중이다. 15일(수)부터 30일(목)까지 진행되는 사전예약 알림
      신청 이벤트는 ▲ 아이폰13 시리즈 3만원 할인권 ▲ 자석형 무선 충전기 '맥 세이프(MagSafe)' ▲ 카카오 쇼핑포인트 12만점 ▲ 웰컴
      기프트 2종(어댑터, 폰케이스)을 비롯해 ▲ 출고 하루 지연 시 1일 1만원 상당의 신세계백화점 상품권 보상(최대 20만원 상당) ▲
      사용하던 스마트폰 반납 시 최대 20만원 추가 보상 등 파격 혜택을 구성했다.
      <br />
      <br />
      사전예약 알림 신청은 핀다이렉트샵 홈페이지 내 ‘이벤트’ 페이지에서 가능하다.
      <br />
      <br />
      | 합리적 MZ에 안성맞춤, 핀다이렉트샵
      <br />
      <br />
      핀다이렉트샵은 세련되고 편리한 UX 설계로 MZ세대에 좋은 반응을 얻고 있는 비대면 통신 가입 플랫폼이다. 카카오톡 채널을
      비롯하여 웹과 모바일을 통한 접근이 가능하며, 카카오페이를 통한 본인인증 등 가입 절차, 배송 및 상담 등 구매 이후 절차 역시
      카카오톡으로 간편하게 이용할 수 있다. 이곳저곳 흩어진 포인트를 한 번에 모아 선 할인 받을 수 있는 것도 강점이다.
      <br />
      <br />
      뿐만 아니라 핀다이렉트샵 내에서는 통신 관련 상품이 한 데 모여 있어, 합리적 소비를 추구하는 소비자들이 빠르고 쉽게 구매하기에
      안성맞춤이다. 최신상 스마트폰과 합리적인 요금이 매칭된 기기결합 요금제, 자급제폰과 찰떡궁합인 알뜰폰(MVNO) 유심 요금제 등
      다양한 종류의 통신 요금제도 간편하게 비교할 수 있다. 신한 플러스, 멜론, SPC 요금제 등 이종산업간 결합한 핀다이렉트샵 만의
      제휴요금제도 준비돼 있다. 뿐만 아니라 최신 5G 스마트폰은 물론, 2단계 품질 검수를 거치는 ‘핀다이렉트케어’ 중고폰까지 한 곳에
      모여 있어 고객 선택권도 한층 강화됐다.
      <br />
      <br />
      <br />
    </p>
  </div>
)

const press15 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브 제휴CR실"
      writer="커뮤니케이션팀 pr@stagefive.com "
      date="2021년 9월 16일 즉시 배포 가능"
      pageInfo="*총 2매"
      title="추석맞이 통신요금 빅찬스…221GB같은 11GB+요금제에 백화점 상품권까지 플러스!"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press15
